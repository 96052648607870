import Carousel from 'react-bootstrap/Carousel';

export default function WorkCarousel({ id, title, itemLength }) {
  const items = Array.from(Array(itemLength).keys());
  return (
    <Carousel interval={2000} controls={false} indicators={false}>
      {items.map((item) => {
        return (
          <Carousel.Item key={item}>
            <img
                className="card-img-top"
                src={"/img/work/" + id + "-site-thumb-" + (item+1) + ".jpg"}
                alt={title + " website screenshot " + (item+1)}
              />
          </Carousel.Item>
        )
      })}
    </Carousel>
  )
}