import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Button, Image } from "react-bootstrap";
import Spinner from 'react-bootstrap/Spinner';
import Toast from 'react-bootstrap/Toast';
import Popover from 'react-bootstrap/Popover';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';

function Footer() {
  const [showEmail, setShowEmail] = useState(false);
  const [loadingEmail, setLoadingEmail] = useState(false);
  const [countDown, setCountDown] = useState(9);
  const [emailCopied, setEmailCopied] = useState(false);

  const email = String.fromCharCode(
      99,
      104,
      114,
      105,
      115,
      100,
      101,
      116,
      109,
      101,
      114,
      64,
      103,
      109,
      97,
      105,
      108,
      46,
      99,
      111,
      109
    );

  const handleEmailClick = () => {
    setLoadingEmail(true);
    setCountDown(9);
    setTimeout(() => {
      setLoadingEmail(false);
      setShowEmail(true);
    }, 3000);
  }

  useEffect(() => { 
    setTimeout(() => {
      if (showEmail && countDown > 0) {
        setCountDown(countDown - 1);
      }
      if (countDown === 0) setShowEmail(false);
    }, 1000);

  }, [showEmail, setShowEmail, countDown, setCountDown]);

  const copyEmail = async () => {
    try {
      await navigator.clipboard.writeText(email);
      console.log('Content copied to clipboard');
      setEmailCopied(true);
    } catch (err) {
      console.error('Failed to copy: ', err);
      setEmailCopied(false);
    }
  }

  const handeEmailCopied = () => {
    setEmailCopied(false);
    setShowEmail(false);
    setCountDown(9);
  }

  return (
    <Container fluid className="container-fluid vh-md-100 mt-5 pb-5" as="section">
        <div
          className="d-flex flex-column align-items-center justify-content-center h-100"
          id="hireme"
        >
          <Row className="vw-md-90 vw-lg-75 px-3 pb-2 px-md-5 pb-md-5 bg-barley-corn-90">
            <Col xs={3} md={4} lg={3} className="d-none d-sm-flex align-items-center justify-content-center">
              <i className="bi bi-person-workspace font-size-huge"></i>
            </Col>
            <Col xs={12} sm={9} md={8} lg={9} className="d-flex flex-column justify-content-center">
              <h2>#HireMe</h2>
              <p>
                I am available for fulltime, contract and hourly work. Take a look at my <Link to="/resume">resume</Link> and recent <Link to="/work">work</Link>, and let's connect if you're hiring or looking for website help.
              </p>
              <ul className="list-inline font-size-regular mb-3">
                <li className="list-inline-item">
                  <a
                    href="https://www.linkedin.com/in/chrisdetmer/"
                    target="_blank"
                    rel="noreferrer"
                     className="btn btn-text btn-link"
                    >LinkedIn</a
                  >
                </li>
                <li className="list-inline-item">
                  <Link to="/resume" className="btn btn-text btn-link">Resume</Link>
                </li>
                <li className="list-inline-item list-item-last">
                  <Button variant="link" className="btn-text btn-email" onClick={() => handleEmailClick()}>Email</Button>
                </li>
              </ul>
              {(loadingEmail || showEmail) && (
                <div className="py-2 mt-2 display-email">
                  {loadingEmail && (<>
                    <Spinner animation="border" role="status" size="sm" />
                    <span className="ms-1">Loading...</span>
                  </>)}
                  {(showEmail && !emailCopied) && (
                    <div className="d-flex flex-column">
                      <div className="d-flex align-items-center justify-content-start">
                        <Image
                          src="/img/loading-BeanEater-1s-200px.svg"
                          alt="Countdown animation"
                          width="50"
                          className="countdown-spinner pe-2"
                        />
                        <span className="countdown-timer">{countDown}</span>
                      </div>
                      <div className="d-flex align-items-center justify-content-start">
                        <a className="copy-text me-2" href={`mailto:${email}`}>{email}</a>
                        <OverlayTrigger 
                          placement="top" 
                          trigger="hover"
                          overlay={
                            <Popover>
                              <Popover.Body>Copy email to clipboard</Popover.Body>
                            </Popover>
                          }>
                          <Button variant="link" className="btn btn-text" aria-label="Copy to clipboard" data-bs-original-title="Copy to clipboard" title="Copy email" onClick={() => copyEmail()}>
                          <i className="bi bi-clipboard"></i>
                        </Button>
                        </OverlayTrigger>
                      </div>
                    </div>
                  )}
                  <Toast 
                    bg="success"
                    show={emailCopied} 
                    position="bottom-start" 
                    onClose={() => handeEmailCopied()}
                    delay={3000} 
                    className="mt-1"
                    autohide
                  >
                    <Toast.Body><strong>Email copied to the clipboard!</strong></Toast.Body>
                  </Toast>
                </div>
              )}
            </Col>
          </Row>
        </div>
      </Container>
  )
}

export default Footer;