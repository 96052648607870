import { Container, Row, Col, Button } from "react-bootstrap";
import WorkCarousel from "./workCarousel";
import PreviewModal from "./previewModal";

export default function WorkSection({ id, title, domain, skills, features, nextId, direction, slideCount, previewVideoURL = null, modalOpen, setCurrentModal, children }) {
  const rowBgColor = direction === "ltr" ? "bg-santa-fe-90" : "bg-bermuda-grey-90";
  const colRightBgColor = direction === "ltr" ? "bg-dingley-90" : "bg-barley-corn-90";

  const handleOpenModal = () => { setCurrentModal(id) }

  const ContentColumn = () => {
    return (
      <Col xs={12} lg={6} xl={7}
        className="d-flex flex-column justify-content-center px-5 pb-5"
      >
        <h3>{title}</h3>
        {children}
        <p><strong>The more notable features include:</strong></p>
        <ul className="list list-bulleted">
          {features.map((item) => {
            return <li key={item}>{item}</li>;
          })}
        </ul>
        {domain && (
          <p>
            <strong>
              <a className="link-external"
                href={"https://" + domain + "/"}
                target="_blank"
                rel="noreferrer"
                >{domain}</a>
              <i className="bi bi-box-arrow-up-right ms-1"></i>
            </strong>
          </p>
        )}
      </Col>
    )
  }

  const PreviewColumn = () => {
    return (
      <Col xs={12} lg={6} xl={5} className={"p-5 " + colRightBgColor}>
        <div className="card border-dark">
          <WorkCarousel id={id} title={title} itemLength={slideCount} onClick={() => handleOpenModal()} />
          <div className="card-body">
            {previewVideoURL && (<Button variant="outline" className="btn-play" onClick={() => handleOpenModal()}>
              Launch preview video <i className="bi bi-play-btn"></i>
            </Button>)}
            <div className="font-code">
              <h3 className="card-title">{">_"}</h3>
              <div className="d-flex align-items-center justify-content-between">
                <ul className="list flex-fill font-size-medium">
                  {skills.primary.map((skill) => {
                    return <li key={skill} className="list-item">{"#" + skill}</li>
                  })}
                </ul>
                <ul className="list flex-fill">
                  {skills.secondary.map((skill) => {
                    return <li key={skill} className="list-item">{"#" + skill}</li>
                  })}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </Col>
    )
  }

  return (
    <Container as="section" className="container-fluid vh-lg-100 my-5 mh-480" id={id}>
      <div
        className="d-flex flex-column align-items-center justify-content-center h-100"
      >
        <Row className={"vw-md-90 vw-lg-75 " + rowBgColor}>
          {direction === "ltr" && (
            <>
              <ContentColumn />
              <PreviewColumn />
            </>
          )}
          {direction !== "ltr" && (
            <>
              <PreviewColumn />
              <ContentColumn />
            </>
          )}
        </Row>
        <Row className="mt-3">
          <Col>
            <p className="fs-3 text-center align-self-end">
              <a href={"#" + nextId}><i className="bi bi-arrow-down-circle"></i></a>
            </p>
          </Col>
        </Row>
      </div>
      <PreviewModal id={id} title={title} url={previewVideoURL} show={modalOpen} handleClose={setCurrentModal} />
    </Container>
  )
}