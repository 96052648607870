import { useState } from 'react';
import { Link } from "react-router-dom";
import Header from "./header";
import Footer from './footer';
import WorkSection from "./workSection";

export default function Work() {
  const [currentModal, setCurrentModal] = useState("");

  const handleCloseModal= () => { setCurrentModal("") }

  return (
    <>
      <Header title="Work" className="vh-50" />
      <section className="container-fluid vh-lg-100 vh-md-50">
        <div
          className="d-flex flex-column align-items-center justify-content-center"
        >
          <div className="row vw-md-90 vw-lg-75 bg-bermuda-grey-90 py-4 px-3">
            <h2>I build websites.</h2>
            <p className="fs-5">
              Developers often contribute to projects where design is not our
              primary job. {" "}
              <strong>We're the builders.</strong> And often times that work is
              invisible to the user, or has been overwritten or removed by more
              recent development efforts.
            </p>
            <p className="fs-5">
              That being said, I've put together a short list of recent projects
              where I was the primary developer, or made a significant
              contribution. This is not a comprehensive list, and does not
              include the majority of my work in more recent JavaScript
              frameworks and techniques.{" "}
              <em
                >Check out my <Link to="/resume">resume</Link> for more on that.</em
              >
            </p>
          </div>
        </div>
        <div className="row mt-3">
            <div className="col">
              <p className="fs-3 text-center align-self-end">
                <a href="#hazel"><i className="bi bi-arrow-down-circle"></i></a>
              </p>
            </div>
          </div>
      </section>
      <WorkSection 
        id="hazel" 
        title="Hazel Technologies" 
        direction="ltr" 
        nextId="slingshot"
        domain="hazeltechnologies.com"
        slideCount={8}
        skills={{
          primary: ["Webflow", "jQuery", "CSS", "HTML", "JavaScript"], 
          secondary: ["Salesforce", "Zapier", "Finsweet", "SEO", "Responsive"]
        }}
        features={[
          "A JavaScript powered comparison slider on the homepage",
          "Multiple content sliders on most pages",
          "Scroll position triggered animations and video",
          "Form integration with Pardot (Salesforce) via Zapier",
          "Custom sorting and filtering using Finsweet"
        ]}
        previewVideoURL="https://player.vimeo.com/video/858834275?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
        modalOpen={currentModal === "hazel"}
        setCurrentModal={setCurrentModal}
        handleCloseModal={handleCloseModal}
      >
        <p>
          Working with the team at {" "}
          <a href="https://www.unicornroad.com/" target="_blank" rel="noreferrer">Unicorn Road</a>, 
          using <strong>Webflow</strong> I constructed a totally
          redesigned site for Hazel Technologies. While Webflow provides
          the visual framework and CMS for building a site, quite a lot of
          custom JavaScript and CSS went into making this site work as
          intended.
        </p>
      </WorkSection>
      <WorkSection 
        id="slingshot" 
        title="Slingshot Aerospace" 
        direction="rtl" 
        nextId="revolution"
        domain={null}
        slideCount={7}
        skills={{
          primary: ["Webflow", "jQuery", "CSS", "HTML", "JavaScript"], 
          secondary: ["ScrollMagic", "Hubspot", "Finsweet", "Responsive"]
        }}
        features={[
          "A completely responsive, JavaScript powered scrolling homepage with background videos and animation.",
          "Scrolling triggered animations across the entire site",
          "Form integration with Hubspot",
          "Custom sorting and filtering using Finsweet"
        ]}
        previewVideoURL="https://player.vimeo.com/video/858837454?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
        modalOpen={currentModal === "slingshot"}
        setCurrentModal={setCurrentModal}
        handleCloseModal={handleCloseModal}
      >
        <p>
          This is another project with the team at {" "} 
          <a href="https://www.unicornroad.com/" target="_blank" rel="noreferrer"
            >Unicorn Road</a
          >. Using Webflow, I built a revamped site for Slingshot
          Aerospace. Both the design team and the client had a very
          specific vision, and there were many rounds of feedback and
          changes. <em>Unfortunately the site has not launched yet.</em>
        </p>
      </WorkSection>
      <WorkSection 
        id="revolution" 
        title="Revolution Cannabis" 
        direction="ltr" 
        nextId="gresham"
        domain="revcanna.com"
        slideCount={8}
        skills={{
          primary: ["Webflow", "CSS", "HTML", "JavaScript"], 
          secondary: ["Responsive", "jQuery", "Hubspot"]
        }}
        features={[
          "Out of the box layout and functionality from Webflow",
          "Scrolling triggered animations across the entire site",
          "Form integration with Hubspot",
        ]}
        previewVideoURL="https://player.vimeo.com/video/868164146?h=8642a1398b&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
        modalOpen={currentModal === "revolution"}
        setCurrentModal={setCurrentModal}
        handleCloseModal={handleCloseModal}
      >
        <p>
          This was my first project with the team at {" "} 
          <a href="https://www.unicornroad.com/" target="_blank" rel="noreferrer"
            >Unicorn Road</a
          >. Big, bold colors and fonts, and lots of imagery. It's pure Webflow, with light enhancements using CSS and JavaScript. 
        </p>
      </WorkSection>
      <WorkSection 
        id="gresham" 
        title="Gresham Partners" 
        direction="rtl" 
        nextId="ichnos"
        domain="greshampartners.com"
        slideCount={7}
        skills={{
          primary: ["WordPress", "CSS", "HTML", "JavaScript"], 
          secondary: ["ScrollMagic", "jQuery", "Responsive"]
        }}
        features={[
          "Horizontal and vertical scrolling triggered page sections",
          "CSS animations and hover interactions",
          "Custom JavaScript filtering for the Team and News pages"
        ]}
        previewVideoURL="https://player.vimeo.com/video/858728164?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
        modalOpen={currentModal === "gresham"}
        setCurrentModal={setCurrentModal}
        handleCloseModal={handleCloseModal}
      >
        <p>
          Working with the team at {" "} 
          <a href="https://www.pivotdesign.com/" target="_blank" rel="noreferrer"
            >Pivot Design</a
          >, using <strong>WordPress</strong> I built a radically
          different site for Gresham Partners, LLC. The horizontal
          scrolling concept leads the vistor through a journey
          illistrating how Gresham Partners work with their clients. Heavy
          use of ScrollMagic was used, and a great deal of CSS wizardry.
        </p>
      </WorkSection>
      <WorkSection 
        id="ichnos" 
        title="Ichnos Sciences" 
        direction="ltr" 
        nextId="dmg"
        domain="ichnossciences.com"
        slideCount={6}
        skills={{
          primary: ["WordPress", "CSS", "HTML", "JavaScript"], 
          secondary: ["ScrollMagic", "jQuery", "Responsive"]
        }}
        features={[
          "JavaScript powered horizontal scrolling homepage",
          "Lightweight theme with minimal animation",
          "Custom font faces"
        ]}
        previewVideoURL="https://player.vimeo.com/video/858847725?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
        modalOpen={currentModal === "ichnos"}
        setCurrentModal={setCurrentModal}
        handleCloseModal={handleCloseModal}
      >
        <p>
          The client already had a bold sense of style. They came to
          <a href="https://www.pivotdesign.com/" target="_blank" rel="noreferrer"
            >Pivot Design</a
          >
          to help launch their updated identity online. With a tight
          deadline and strict styling requirements I put together a quick
          loading site with a little horizontal scroll enhancement on the
          homepage.
        </p>
      </WorkSection>
      <WorkSection 
        id="dmg" 
        title="DMG Consulting" 
        direction="rtl" 
        nextId={null}
        domain="dmgconsult.com"
        slideCount={5}
        skills={{
          primary: ["WordPress", "CSS", "HTML", "JavaScript"], 
          secondary: ["WooCommerce", "jQuery", "Responsive", "GDPR"]
        }}
        features={[
          "Custom, responsive, lightweight theme",
          "WooCommerce shop integrated with PayPal",
          "Termly cookie, privacy and terms management"
        ]}
        modalOpen={currentModal === "dmg"}
        setCurrentModal={setCurrentModal}
        handleCloseModal={handleCloseModal}
      >
        <p>
          DMG provides contact center and back office consulting services. They are small but mighty. Via their website they sell reports and share whitepapers, articles and videos highlighting the latest technology, trends and best practices. I took on managing their website in 2017 and have rebuilt it twice in WordPress and WooCommerce.
        </p>
      </WorkSection>
      <Footer />
    </>
  )
}