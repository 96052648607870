import { useEffect, useRef } from 'react';
import { useLocation } from "react-router";
import { Link } from "react-router-dom";

export default function Header({ title = "", className = "" }) {
  const navRef = useRef(null);
  const location = useLocation();

  const scrollToElement = (elementID) => {
    const element = document.getElementById(elementID);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }

  const handleClickScroll = (event) => {
    event.preventDefault();
    scrollToElement(event.target.href)
  };

  useEffect(() => {
    const sticky = navRef.current.offsetTop;
    const handleScroll = () => {
      if (window.scrollY >= sticky) {
        navRef.current.classList.add("sticky");
        document.body.classList.add("scrolled");
      } else {
        navRef.current.classList.remove("sticky");
        document.body.classList.remove("scrolled");
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    if (location.hash !== "") {
      setTimeout(() => {
        scrollToElement(location.hash.replace("#", ""));
      }, 250);
    }
  }, [location])

  return (
    <header className={`container-fluid px-0 ${className ? className : "vh-100 mh-480"}`}>
      <div className="d-flex flex-column align-items-center justify-content-center h-100 hero">
        <h1 className="align-self-center">Chris Detmer{title && ` | ${title}`}</h1>
        <ul
          className={`nav flex-column flex-md-row justify-content-center jump-nav${title && " sticky"}`}
          role="navigation"
          id="navBar"
          ref={navRef}
        >
          <li className="nav-item">
            <Link 
              className="nav-link bg-santa-fe-90 d-flex flex-md-column justify-content-between" 
              to="/#developer" onClick={(e) => handleClickScroll}>
              <span className="d-block nav-link-text">Developer</span>
              <i className="bi bi-code-slash nav-link-icon"></i>
            </Link>
          </li>
          <li className="nav-item">
            <Link
              className="nav-link bg-bermuda-grey-90 d-flex flex-md-column justify-content-between"
              to="/#musician" onClick={(e) => handleClickScroll}
            >
              <span className="d-block nav-link-text">Musician</span>
              <i className="bi bi-music-note-beamed nav-link-icon"></i>
            </Link>
          </li>
          <li className="nav-item">
            <Link
              className="nav-link bg-dingley-90 d-flex flex-md-column justify-content-between"
              to="/#about" onClick={(e) => handleClickScroll}
            >
              <span className="d-block nav-link-text">About</span>
              <i className="bi bi-book nav-link-icon"></i>
            </Link>
          </li>
          <li className="nav-item">
            <Link
              className="nav-link bg-barley-corn-90 d-flex flex-md-column justify-content-between"
              to="/#hireme" onClick={(e) => handleClickScroll}
            >
              <span className="d-block nav-link-text">Hire Me</span>
              <i className="bi bi-person-workspace nav-link-icon"></i>
            </Link>
          </li>
        </ul>
      </div>
    </header>
  )
}
