import { useState, useEffect } from "react";
import { useLocation } from "react-router";

const ScrollToTop = (props) => {
  const location = useLocation();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
  }, []);

  useEffect(() => {
    if (location.pathname !== "/") window.scrollTo(0, 0);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, [location]);

  return (
    <>
      {props.children}
      {loading && (
        <div className="loading-overlay d-flex flex-column justify-content-center align-items-center">
          <div className="d-flex justify-content-center align-items-center w-100 h-100">
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        </div>
      )}
    </>
  )
};

export default ScrollToTop;