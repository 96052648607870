import Modal from 'react-bootstrap/Modal';

export default function PreviewModal({ id, title, url, show, handleClose }) {
  return (
    <>
    <Modal show={show} size="lg" onHide={handleClose} id={id + "PreviewModal"}>
      <Modal.Header closeButton closeVariant="white">
        <Modal.Title as="div">{title} Site Preview</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="video-player-wrapper">
          <iframe src={url} 
            allow="autoplay; fullscreen; picture-in-picture" 
            className="video-player" 
            title={title + " site preview"}>
          </iframe>
        </div>
      </Modal.Body>
    </Modal>
    </>
  )
}