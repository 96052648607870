import Header from "./header";
import Footer from './footer';
import Image from 'react-bootstrap/Image';

export default function FourOhFour() {
  return (
    <>
      <Header title="Page Not Found" className="vh-75" />
        <div className="d-flex flex-column align-items-center justify-content-center bg-bermuda-grey-90 vw-md-90 vw-lg-75 mx-auto">
          <h2>Quoth the raven, 404.</h2>
          <Image src="/img/raven-art.png" alt="A drawing of a common raven" fluid />
        </div>
      <Footer />
    </>
  )
}