import { Outlet } from "react-router-dom";
import './App.scss';

export default function Layout() {
  return (
    <div role="main" id="main">
      <Outlet />
    </div>
  )
}
